export default {
  name: 'BrandAnalyticsFansGenderAge',

  props: {
    women: {
      default: 0,
      type: Number,
    },
    men: {
      default: 0,
      type: Number,
    },
  },

  computed: {
    total() {
      return this.women + this.men;
    },
  },

  methods: {
    getPercent(value) {
      return (value * 100) / this.total;
    },
  },
};
